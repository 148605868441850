import React, { useState } from "react";
import { connect } from "react-redux";
import ChatModal from "../components/chatModal/ChatModal";
import chatbotIcon from "../assets/images/isotipo-normal.png";
import chatbotLoadingIcon from "../assets/images/isotipo-loading.png";
import "./ChatbotService.css";

function ChatbotService(props) {
  const [showChatbot, toggleChatbot] = useState(false);
  const { auth } = props;

  return (
    <>
      <div className="app-chatbot-container">
        {showChatbot && <ChatModal onClose={() => toggleChatbot(false)} auth={auth} />}
      </div>
      
      <button
        className="app-chatbot-button"
        onClick={() => toggleChatbot((prev) => !prev)}
      >
        <img
          src={showChatbot ? chatbotLoadingIcon : chatbotIcon}
          alt="Chatbot Icon"
          className="app-chatbot-button-icon"
        />
      </button>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ChatbotService);