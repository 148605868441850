import React, { useState } from 'react';
import WelcomeView from './ChatWelcomeView';
import { TextField, Button } from '@material-ui/core';
import ReactMarkdown from 'react-markdown'; 
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { connect } from 'react-redux';
import ImageIcon from '../../../assets/image_icon.svg';
import ThumbDownIcon from '../../../assets/thumb_down_icon.svg';
import { dislikeMessageBatch, changeTip } from '../api/chatApi';
import '../styles/ChatMessages.css';

const ChatMessages = ({ messages, onSendMessage, auth, prefilledText }) => {
    const [inputValue, setInputValue] = useState(prefilledText || "");;
    const [activeModel, setActiveModel] = useState('GPT4o');
    const [uploadedImage, setUploadedImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [activeModels, setActiveModels] = useState({});

    const handleImageUpload = (event) => {
        event.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/gif, image/webp';
        fileInput.click();
    
        fileInput.onchange = async (e) => {
            const file = e.target.files[0];
            if (!file) return;
    
            const validFormats = ['jpeg', 'png', 'gif', 'webp'];
            const extension = file.name.split('.').pop().toLowerCase();
    
            if (!validFormats.includes(extension)) {
                setErrorMessage(`Formato no permitido. Solo se aceptan: ${validFormats.join(', ')}`);
                return;
            }
    
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setUploadedImage({ base64, name: file.name, extension });
                setErrorMessage(null);
            };
        };
    };
    
    const handleRemoveImage = () => {
        setUploadedImage(null);
        setErrorMessage(null);
    };

    const handleSend = () => {
        if (inputValue.trim() !== '' || uploadedImage) {
            const message = {
                text: inputValue || '',
                image: uploadedImage ? {
                    base64: uploadedImage.base64,
                    extension: uploadedImage.extension
                } : null,
            };
            onSendMessage(message);
    
            setInputValue('');
            setUploadedImage(null);
        }
    };
    
    const handleDislike = (message) => {
        try {
            console.log("Dislike:", message);
            dislikeMessageBatch(message.messageBatchId);
        }
        catch (error) {
            console.error("Error al enviar dislike:", error);
        }
    };

    const handleTipChange = (messageBatchId, model) => {
        const isLastMessage = messages[messages.length - 1].messageBatchId === messageBatchId;

        setActiveModels((prev) => ({
            ...prev,
            [messageBatchId]: model,
        }));
    
        let chosenModel = model;
        switch (model) {
            case "GPT4o":
                chosenModel = "GPT_4o";
                break;
            case "Claude":
                chosenModel = "CLAUDE_3_5";
                break;
            case "GPT4oStackOverflow":
                chosenModel = "GPT_4o_STACKOVERFLOW";
                break;
            default:
                break;
        }
    
        if (!isLastMessage) {
        changeTip(messageBatchId, chosenModel);
        }
    };
    
    
    const CodeBlock = ({ language, value }) => (
        <div className="code-block">
            <SyntaxHighlighter
                language={language}
                style={materialDark}
                wrapLongLines={true}
                wrapLines={true}
                lineProps={{ style: { wordBreak: 'break-word', whiteSpace: 'pre-wrap' } }}
            >
                {value}
            </SyntaxHighlighter>
            <Button
                className="copy-button"
                onClick={() => navigator.clipboard.writeText(value)}
            >
                Copiar
            </Button>
        </div>
    );

    const markdownComponents = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codeValue = String(children).replace(/\n$/, '');
            return !inline && match ? (
                <CodeBlock language={match[1]} value={codeValue} />
            ) : (
                   <code className={`inline-code ${className || ''}`} {...props}>
                    {children}
                </code>
            );
        },
    };

    const renderBotMessage = (msg) => {

        const senderRole = msg.sender === 'user' ? auth.name : 'Asistente';
    
        if (msg.sender === 'bot' && msg.responses) {
            const activeModelForMsg = activeModels[msg.messageBatchId] || 'GPT4o';
            const response = msg.responses[activeModelForMsg] || "Cargando...";
        
            return (
                <div className={`message-wrapper ${msg.sender}-wrapper`}>
                    <span className="message-sender">{senderRole}</span>
                    <div className={`message-container ${msg.sender}-message`}>
                        <div className="bot-tips">
                            <div className="tip-buttons">
                                <Button
                                    variant={activeModelForMsg === 'GPT4o' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleTipChange(msg.messageBatchId, 'GPT4o')}
                                >
                                    Tip 1
                                </Button>
                                <Button
                                    variant={activeModelForMsg === 'Claude' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleTipChange(msg.messageBatchId, 'Claude')}
                                >
                                    Tip 2
                                </Button>
                                <Button
                                    variant={activeModelForMsg === 'GPT4oStackOverflow' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handleTipChange(msg.messageBatchId, 'GPT4oStackOverflow')}
                                >
                                    Tip 3
                                </Button>
                            </div>
                            <div className="message-content">
                                <ReactMarkdown components={markdownComponents}>
                                    {response}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    
        return (
            <div className={`message-wrapper ${msg.sender}-wrapper`}>
                <span className="message-sender">{senderRole}</span>
                <div className={`message-container ${msg.sender}-message`}>
                    <div className="message-content">
                        <ReactMarkdown components={markdownComponents}>
                            {msg.text}
                        </ReactMarkdown>
                        {msg.imageBase64 && (
                            <div className="message-image">
                                <img src={`data:image/png;base64,${msg.imageBase64}`} alt="Imagen enviada" />
                            </div>
                        )}
                    </div>
                    {msg.sender === 'bot' && (
                    <div className="chat-dislike-container">
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => handleDislike(msg)}
                            className="chat-dislike-button"
                        >
                            <img src={ThumbDownIcon} alt="dislike" />
                        </Button>
                    </div>
                    )}
                </div>
            </div>
        );
    };    

    return (
        <div className="chat-messages">
            {messages.length === 0 && (
                <div className="welcome-container">
                    <WelcomeView setCurrentMessage={setInputValue} />
                </div>
            )}
            <div className="message-list">
                {messages.map((msg, index) => renderBotMessage(msg))}
            </div>
            {uploadedImage && (
                <div className="uploaded-image-preview">
                    <button className="remove-image-button" onClick={handleRemoveImage}>
                        ✖
                    </button>
                    <img src={`data:image/png;base64,${uploadedImage.base64}`} alt="Preview" className="image-preview" />
                    <span className="image-name">{uploadedImage.name}</span>
                </div>
            )}

            <div className="chat-message-input">
                
                <Button
                    variant="contained"
                    color="gray"
                    onClick={handleImageUpload}
                    className="add-photo-button"
                >
                    <img src={ImageIcon} alt="add photo" className="chat-add-image-icon"/>
                </Button>
                <TextField
                    label="Escribe tu mensaje."
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                    className="message-input-field"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    className="send-button"
                >
                    Enviar
                </Button>
            </div>
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
    
export default connect(mapStateToProps)(ChatMessages);