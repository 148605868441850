import React from 'react';
import chatbotIcon from '../../../assets/images/isotipo-normal.png';

const ChatHeader = ({ onClose }) => (
    <div className="chat-header">
        <div className="chat-header-left">
            <img src={chatbotIcon} alt="chatbot icon" className="title-logo" />
            <h2>ZmartBot Chat</h2>
        </div>
        <button onClick={onClose}>✖</button>
    </div>
);

export default ChatHeader;
