import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ChatHeader from './Components/ChatHeader';
import ChatMessages from './Components/ChatMessages';
import SideBar from './Components/ChatSideBar';

import { createChat, sendMessage, fetchMessages, fetchConversations, changeChatTitle } from './api/chatApi';
import '../chatModal/styles/ChatModal.css';

const ChatModal = ({ onClose, auth, userId, prefilledText, showHiddenChats=false }) => {

    const user = userId || auth.id;
    const mail = auth.email;
    
    const [messages, setMessages] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [isSendingMessage, setIsSendingMessage] = useState(false);

    const updateConversations = (updatedConversations) => {
        setConversations(updatedConversations);
    };    

    useEffect(() => {
        if (!isSendingMessage) return;
        
        const messageList = document.querySelector('.message-list');
        if (!messageList) return;

        requestAnimationFrame(() => {
            messageList.scrollTop = messageList.scrollHeight;
        });

        setIsSendingMessage(false);
    }, [isSendingMessage]);
    
    const handleSendMessage = async (newMessage) => {
        console.log("Enviando mensaje:", newMessage);
        setIsSendingMessage(true);
        const cleanedMessage = newMessage.text.replace(/\.undefined$/, "");
    
        try {
            let chatId = selectedChatId;
            let isNewChat = false;
    
            if (!chatId) {
                console.log("No hay chat seleccionado. Creando nuevo chat...");
                const newChat = await createChat(user, mail);
                chatId = newChat.conversation.id;
                isNewChat = true;
    
                console.log("Chat creado:", newChat);
                setConversations((prev) => [
                    { id: chatId, title: "Nuevo Chat" },
                    ...prev,
                ]);
                setSelectedChatId(chatId);
            }
            let imageBase64 = newMessage.image ? newMessage.image.base64 : '';
            let imageExtension = newMessage.image ? newMessage.image.extension : '';
            setMessages((prev) => [...prev, { sender: 'user', text: cleanedMessage, imageBase64 }]);
    
            const responses = { GPT4o: "", GPT4oStackOverflow: "", Claude: "" };
            let messageBatchId = "";

            console.log("Enviando mensaje a la API...");
            console.log('newMessage', newMessage);

            if (!newMessage.image) {
                newMessage.image = { base64: null };
            }
    
            await sendMessage(chatId, cleanedMessage, newMessage.image.base64, imageExtension, (chunk) => {
                const { model, data, batchId: chunkBatchId } = chunk;
                if (!data && !chunkBatchId) return;
    
                if (chunkBatchId) {
                    messageBatchId = chunkBatchId;
                }
    
                if (data) {
                    responses[model] += data;
    
                    setMessages((prev) => {
                        const lastMessage = prev[prev.length - 1];
    
                        if (lastMessage && lastMessage.sender === 'bot' && lastMessage.responses) {
                            return [
                                ...prev.slice(0, -1),
                                {
                                    ...lastMessage,
                                    responses: {
                                        ...lastMessage.responses,
                                        [model]: responses[model],
                                    },
                                    messageBatchId,
                                },
                            ];
                        } else {
                            return [
                                ...prev,
                                { sender: 'bot', responses: { [model]: data }, messageBatchId },
                            ];
                        }
                    });
                }
            });
    
            if (isNewChat) {
                await updateChatTitle(chatId, newMessage.text);
            }
    
            if (conversations[0] && conversations[0].id !== chatId) {
                setConversations((prev) => {
                    const updatedConversations = prev.filter((chat) => chat.id !== chatId);
                    const chatToUpdate = conversations.find((chat) => chat.id === chatId);
    
                    return chatToUpdate
                        ? [{ id: chatId, title: chatToUpdate.title }, ...updatedConversations]
                        : updatedConversations;
                });
            }
    
            await loadConversations();
        } catch (error) {
            console.error("Error enviando mensaje:", error);
        }
    };
    
    const updateChatTitle = async (chatId, firstMessage) => {
        try {
            const response = await changeChatTitle(chatId, firstMessage);
            console.log("Título actualizado en backend:", response);
            await loadConversations();
        } catch (error) {
            console.error("Error actualizando el título del chat:", error);
        }
    };
    
    const loadConversations = async () => {
        try {
            const fetchedConversations = await fetchConversations(user);
            console.log("Conversaciones obtenidas:", fetchedConversations);

            const filteredConversations = fetchedConversations.filter(convo => convo.visible !== showHiddenChats);
            setConversations(filteredConversations);
        } catch (error) {
            console.error("Error al obtener las conversaciones:", error);
        }
    };
    
    useEffect(() => {
        loadConversations();
    }, [user]);
    
    const handleSelectChat = async (chatId) => {
        setSelectedChatId(chatId);
        console.log("Chat seleccionado:", chatId);
    
        if (chatId) {
            try {
                const response = await fetchMessages(chatId);
                console.log("Respuesta de la API al elegir chat:", response);
    
                if (response.messages) {
                    
                    const formattedMessages = response.messages.map((msg) => {
                        const cleanText = (msg.content || "Sin contenido").replace(/\undefined$/, "");
                        return {
                            sender: msg.role === 'user' ? 'user' : 'bot',
                            text: cleanText,
                            messageBatchId: msg.messageBatchId,
                            imageBase64: msg.imageBase64 || null,
                            createdAt: msg.createdAt,
                        };
                    });
    
                    const sortedMessages = formattedMessages.sort(
                        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    );
    
                    console.log("Mensajes formateados y ordenados:", sortedMessages);
                    setMessages(sortedMessages);
                    if (response.messageBatch && sortedMessages.length > 0) {
                        console.log("mensaje batch:", response.messageBatch);
                        const GTP4oMessage = response.messageBatch.Messages.find((msg) => msg.model === 'GPT4o');
                        const ClaudeMessage = response.messageBatch.Messages.find((msg) => msg.model === 'Claude 3.5');
                        const GTP4oStackOverflowMessage = response.messageBatch.Messages.find((msg) => msg.model === 'GPT4o + Stackoverflow');
                        const messageBatchId = response.messageBatch.id;
    
                        const lastMessage = {
                            sender: 'bot',
                            messageBatchId,
                            responses: {
                                GPT4o: GTP4oMessage ? GTP4oMessage.content : "Sin contenido",
                                Claude: ClaudeMessage ? ClaudeMessage.content : "Sin contenido",
                                GPT4oStackOverflow: GTP4oStackOverflowMessage ? GTP4oStackOverflowMessage.content : "Sin contenido",
                            }
                        };
                        console.log("Último mensaje:", lastMessage);
                            setMessages((prev) => [...prev, lastMessage]);
                        }
                } else {
                    console.error("La respuesta no contiene mensajes. Recibido:", response);
                    setMessages([]);
                }
            } catch (error) {
                console.error("Error cargando mensajes:", error);
                setMessages([]);
            }
        } else {
            setMessages([]);
        }
    };

    return (
        <div className="chat-modal">
            <ChatHeader onClose={onClose} />
            <div className="chat-body">
                <SideBar setSelectedChatId={handleSelectChat} conversations={conversations} setConversations={updateConversations}/>
                <div className="chat-messages-and-input-container">
                    <ChatMessages
                        messages={messages}
                        setMessages={setMessages}
                        onSendMessage={handleSendMessage}
                        prefilledText={prefilledText}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
  
export default connect(mapStateToProps)(ChatModal);
